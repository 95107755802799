import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getAPIUrl, getWebsiteUrl} from '../../utils/Url';
import {motion} from 'framer-motion';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router";
import {passwordStrength} from "check-password-strength";
import {initializeGoogleSignIn} from '../../utils/googleSignIn';
import {useTheme} from "../../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import Urls from "../../utils/urls";
import ThemeToggleButton from "../../components/ThemeToggleButton";
import {getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles} from "../../utils/Common";

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false); // Added state for accepting terms
    const [hcaptchaToken, setHecaptchaToken] = useState('');
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const navigate = useNavigate();
    const {isDarkMode, toggleTheme} = useTheme();

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate);
    }, [navigate]);

    const handleVerificationSuccess = async (token: React.SetStateAction<string>, ekey: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(`${getAPIUrl()}/verify-hcaptcha`, {
                hcaptchaToken: token,
                ekey: ekey,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                console.log('HCaptcha token verified');
                setHecaptchaToken(token);
                setError('');
            } else {
                console.error('Error during hcaptcha verification:', response.data);
                setError('An error occurred during hcaptcha verification');
            }
        } catch (error: any) {
            console.error('Error during hcaptcha verification:', error);
            setError('An error occurred during hcaptcha verification');
        } finally {
            setIsVerifying(false);
        }
    };

    const handleSignUp = async () => {
        if (!email.trim()) {
            setError('Email cannot be empty');
            return;
        }

        if (!password.trim() || !confirmPassword.trim()) {
            setError('Password and confirm password cannot be empty');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setError('Invalid email address, must be in the form of an email address');
            return;
        }

        // Password security requirements
        if (passwordStrength(password).id !== 3) {
            setError('Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!hcaptchaToken) {
            setError('Please complete/retry the CAPTCHA');
            return;
        }

        if (!acceptTerms) {
            setError('You must accept the terms and conditions to sign up.');
            return;
        }

        setIsSigningUp(true);

        await axios.post(`${getAPIUrl()}/signup`, {
            email: email,
            password: password,
            hcaptchaToken: hcaptchaToken,
            url: getWebsiteUrl()
        })
            .then((response: any) => {
                if (response.status === 200) {
                    navigate(`/verification/${email}/signup`);
                }
            })
            .catch((error: any) => {
                if (error.response.status === 400) {
                    setError('Email already exists');
                } else {
                    console.error('Error during signup:', error);
                    setError('An error occurred during signup');
                }
            })
            .finally(() => {
                setIsSigningUp(false);
            });
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Sign Up</title>
            <meta name="description" content="Sign up for a Revise Wizard account"/>
            <link rel="canonical" href={getWebsiteUrl() + Urls.SIGN_UP}/>
        </Helmet>
        <div className="min-h-screen flex flex-col items-center justify-center overflow-hidden"
             style={getCurrentStyles(isDarkMode)}>
            <ThemeToggleButton toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>

            <div className="absolute top-8 right-20">
                <Link to="/">
                    <button
                        className="px-4 py-2 rounded mr-4 transition duration-300 transform hover:scale-110"
                        style={getCurrentButtonStyles(isDarkMode)}
                    >
                        Home
                    </button>
                </Link>
                <Link to="/login">
                    <button
                        className="px-4 py-2 rounded transition duration-300 transform hover:scale-110"
                        style={getCurrentButtonStyles(isDarkMode)}
                    >
                        Login
                    </button>
                </Link>
            </div>

            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="text-4xl font-extrabold mb-8 text-center"
                style={{color: getCurrentStyles(isDarkMode).color}}
            >
                Sign Up
            </motion.h1>

            <div id="google-signin-button"></div>

            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4 w-96 text-center">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2" style={{color: getCurrentStyles(isDarkMode).color}}>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2"
                       style={{color: getCurrentStyles(isDarkMode).color}}>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2" style={{color: getCurrentStyles(isDarkMode).color}}>Confirm
                    Password:</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <input
                    type="checkbox"
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                />
                <span className="ml-2" style={{color: getCurrentStyles(isDarkMode).color}}>
                    I accept the <Link to="/user-agreement"
                                       className="text-blue-500 hover:underline">User Agreement</Link>,
                    <Link to="/terms-of-service" className="text-blue-500 hover:underline"> Terms of Service</Link>, and
                    <Link to="/privacy-policy" className="text-blue-500 hover:underline"> Privacy Policy</Link>.
                </span>
            </div>

            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="px-4 py-2 rounded transition duration-300"
                onClick={handleSignUp}
                style={getCurrentButtonStyles(isDarkMode)}
                disabled={isVerifying || isSigningUp}
            >
                {isVerifying ? 'Please wait...' : isSigningUp ? (
                    <div className="flex space-x-1">
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Sign Up'}
            </motion.button>
        </div>
    </>;
};

export default SignUp;
