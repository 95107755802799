import React, {ChangeEvent, useState} from 'react';
import axios from 'axios';
import {getAPIUrl, getWebsiteUrl} from '../../utils/Url';
import {motion} from 'framer-motion';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {Link} from 'react-router-dom';
import {useTheme} from "../../components/ThemeContext";
import Urls from '../../utils/urls';
import {Helmet} from "react-helmet-async";
import ThemeToggleButton from "../../components/ThemeToggleButton";
import {getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles} from '../../utils/Common';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState<string>('');
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const {isDarkMode, toggleTheme} = useTheme();

    const handleVerificationSuccess = (token: string) => {
        setHecaptchaToken(token);
        setError('');
    };

    const handleResetPasswordRequest = async () => {
        if (!email.trim()) {
            setError('Email cannot be empty');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setError('Invalid email address, must be in the form of an email address');
            return;
        }

        if (!hcaptchaToken) {
            setError('Please complete the CAPTCHA');
            return;
        }

        setIsRequesting(true);
        setError('');
        setSuccessMessage('');

        try {
            const resetPasswordLink = `${window.location.origin}/reset-password/`; // Base link for reset password
            const response = await axios.post(`${getAPIUrl()}/reset`, {
                email, resetPasswordLink, hcaptchaToken, url: getWebsiteUrl()
            });

            if (response.status === 200) {
                setSuccessMessage('Check your email for a password reset link');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 404) {
                    setError('Email does not exist');
                } else {
                    console.error('Error during password reset request:', error);
                    setError('An error occurred during the password reset request');
                }
            }
        } finally {
            setIsRequesting(false);
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Reset Password Request</title>
            <meta name="description" content="Request a password reset link"/>
            <link rel="canonical" href={getWebsiteUrl() + Urls.RESET_PASSWORD_REQUEST}/>
        </Helmet>
        <div className="min-h-screen flex flex-col items-center justify-center overflow-hidden"
             style={getCurrentStyles(isDarkMode)}>

            <ThemeToggleButton toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>

            <div className="absolute top-8 right-20">
                <Link to={Urls.HOME}>
                    <button
                        className="px-4 py-2 rounded mr-4 transition duration-300 transform hover:scale-110"
                        style={getCurrentButtonStyles(isDarkMode)}> Home
                    </button>
                </Link>
                <Link to={Urls.LOGIN}>
                    <button
                        className="px-4 py-2 rounded mr-4 transition duration-300 transform hover:scale-110"
                        style={getCurrentButtonStyles(isDarkMode)}> Login
                    </button>
                </Link>
            </div>

            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="text-4xl font-extrabold mb-8 text-center"
            >
                Reset Password
            </motion.h1>

            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4 w-96 text-center">
                    {error}
                </div>
            )}

            {successMessage && (
                <div className="bg-green-500 text-white px-4 py-2 rounded mb-4 w-96 text-center">
                    {successMessage}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full px-4 py-2 rounded text-black"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token) => handleVerificationSuccess(token)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="bg-green-500 text-white px-4 py-2 rounded transition duration-300"
                onClick={handleResetPasswordRequest}
                disabled={isRequesting}
                style={getCurrentButtonStyles(isDarkMode)}
            >
                {isRequesting ? 'Requesting...' : 'Reset Password'}
            </motion.button>
        </div>
    </>;
};

export default ResetPasswordRequest;
