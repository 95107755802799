import {setJWT} from "./Cookies";
import {NavigateFunction} from "react-router";
import {getAPIUrl, getWebsiteUrl} from "./Url";
import Urls from "./urls";
import axios from "axios";

export function initializeGoogleSignIn(clientId: string, navigate: NavigateFunction) {
    if (window.google) {
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: (response: any) => handleCredentialResponse(response, navigate),
        });

        window.google.accounts.id.renderButton(
            document.getElementById("google-signin-button"),
            {
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en",
            }
        );
    }
}

const handleCredentialResponse = async (response: any, navigate: NavigateFunction) => {
    navigate(Urls.GOOGLE_LOADING);

    if (response.credential) {
        const jwt = response.credential;
        try {
            const apiResponse = await axios.post(`${getAPIUrl()}/auth/google`, {
                jwt: jwt,
                url: getWebsiteUrl()
            });

            if (apiResponse.status === 200) {
                const jwt = apiResponse.data;
                setJWT('jwt', jwt);
                navigate(Urls.HOME)
                window.location.reload();
            } else {
                console.error('Error during Google sign in:', apiResponse.data);
                navigate(Urls.LOGIN);
            }
        } catch (error) {
            console.error('Error during Google sign in:', error);
            navigate(Urls.LOGIN);
        }
    } else {
        console.error('Error during Google sign in:', response);
        navigate(Urls.LOGIN);
    }
};
