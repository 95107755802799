import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {getCurrentStyles} from "../../utils/Common";
import {useTheme} from "../../components/ThemeContext";

const LoggingOut = () => {
    const navigate = useNavigate();
    const {isDarkMode} = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 3000); // Adjust the delay as needed

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [navigate]);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center overflow-hidden"
             style={getCurrentStyles(isDarkMode)}>
            <h1 className="text-4xl font-extrabold mb-8 text-center">Logging out, please wait...</h1>
        </div>
    );
};

export default LoggingOut;
