import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useTheme} from "../../components/ThemeContext";
import {ReactTyped} from "react-typed";
import axios from "axios";
import {getAPIUrl, getWebsiteUrl} from "../../utils/Url";
import {Helmet} from "react-helmet-async";
import Urls from "../../utils/urls";
import ThemeToggleButton from "../../components/ThemeToggleButton";
import {getCurrentButtonStyles, getCurrentStyles} from "../../utils/Common";

const MathPage: React.FC = () => {
    const {isDarkMode, toggleTheme} = useTheme();
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);

    useEffect(() => {
        const fetchTopics = async () => {
            setLoadingTopics(true);
            let level = ['aslevel', 'alevel'][Math.floor(Math.random() * 2)];
            try {
                const response = await axios.get(`${getAPIUrl()}/topicList`, {params: {level}});
                const topicsData = response.data.topics.map((topic: string) => ({value: topic, label: topic}));
                setTopics(topicsData);
            } catch (error: any) {
                console.error('Error fetching topics:', error);
                setTopics([]); // Clear topics on error
            } finally {
                setLoadingTopics(false);
            }
        };

        fetchTopics().then(r => r);
    }, []);

    return <>
        <Helmet>
            <title>Revise Wizard - Math</title>
            <meta name="description"
                  content="Revise Wizard's math section, where you can access past papers, topical questions, and notes to help you prepare for your exams."/>
            <link rel="canonical" href={getWebsiteUrl() + Urls.MATH}/>
        </Helmet>
        <div className="min-h-screen flex flex-col justify-center items-center p-8"
             style={getCurrentStyles(isDarkMode)}>
            <ThemeToggleButton toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
            <div className="w-full max-w-7xl">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                    {/* Paper Retrieval Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="text-2xl font-bold mb-4">Paper Retrieval</h2>
                        <p className="mb-4">
                            Find past papers for your math exams. Access a wide range of papers to practice and prepare
                            for your tests.
                        </p>
                        <Link to={Urls.MATH_EXAM_PAPER_RETRIEVAL}>
                            <button className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Paper Retrieval
                            </button>
                        </Link>
                    </div>

                    {/* Topical Questions and Builder Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="text-2xl font-bold mb-4">Topical Paper Generator</h2>
                        <p className="mb-4">
                            Build and solve topical questions to enhance your understanding of different math concepts.
                            Customize your practice to focus on areas where you need improvement.
                        </p>
                        <Link to={Urls.MATH_TOPIC_PAPER_GENERATOR}>
                            <button className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Topical Selector
                            </button>
                        </Link>
                    </div>

                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="text-2xl font-bold mb-4">Exam Paper Builder</h2>
                        <p className="mb-4">
                            Create your own exam papers by selecting questions from our question bank. Choose questions
                            based on topics you want to practice and generate a custom exam paper.
                        </p>
                        <Link to={Urls.MATH_EXAM_PAPER_BUILDER}>
                            <button className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Exam Paper Builder
                            </button>
                        </Link>
                    </div>


                    {/* Topical Notes Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="text-2xl font-bold mb-4">Topical Notes</h2>
                        <p className="mb-4">
                            Access comprehensive notes on various math topics. These notes will help you understand and
                            revise key concepts efficiently.
                        </p>
                        <Link to="">
                            <button className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300"
                                    style={getCurrentButtonStyles(isDarkMode)} disabled>
                                Coming Soon
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="text-2xl font-bold mt-12">
                    Math topics available:
                    {loadingTopics ? (
                        <span>Loading topics...</span>
                    ) : (
                        <ReactTyped
                            strings={topics.map((topic) => topic.label)}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                            className="ml-2"
                            style={{color: '#86C232'}}
                        />
                    )}
                </div>
            </div>
        </div>
    </>;
};

export default MathPage;
