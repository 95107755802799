import React from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from "../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import Urls from "../utils/urls";
import {getWebsiteUrl} from "../utils/Url";
import {getCurrentButtonStyles, getCurrentStyles} from "../utils/Common";
import ThemeToggleButton from '../components/ThemeToggleButton';

const NotFound = () => {
    const {isDarkMode, toggleTheme} = useTheme();


    return <>
        <Helmet>
            <title>Revise Wizard - 404</title>
            <meta name="description" content="404 - Page Not Found, the page you are looking for does not exist."/>
            <link rel="canonical" href={getWebsiteUrl() + Urls.NOT_FOUND}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center flex-grow p-8 pb-24"
             style={getCurrentStyles(isDarkMode)}>
            <ThemeToggleButton toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
            <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
            <p className="text-lg mb-6">Oops! The page you are looking for does not exist.</p>
            <Link to="/">
                <button
                    className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300 transform hover:scale-110"
                    style={getCurrentButtonStyles(isDarkMode)}>
                    Go to Home
                </button>
            </Link>
        </div>
    </>;
};

export default NotFound;
