import React from 'react';
import {Route, Routes} from "react-router";
import Home from "./pages/Home";
import Login from "./pages/oauth/Login";
import SignUp from "./pages/oauth/SignUp";
import MainNavbar from "./components/MainNavbar";
import LoginNavbar from "./components/LoginNavbar";
import Verification from "./pages/oauth/Verification";
import NotFound from "./pages/NotFound";
import ResetPasswordRequest from "./pages/oauth/ResetPasswordRequest";
import ResetPassword from "./pages/oauth/ResetPassword";
import useAuth from "./utils/useAuth";
import UserAgreement from "./pages/terms/UserAgreement";
import TermsOfService from "./pages/terms/TermsOfService";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import Footer from "./components/Footer";
import GoogleLoading from "./components/GoogleLoading";
import LoggingOut from "./pages/oauth/LoggingOut";
import {ThemeProvider} from './components/ThemeContext';
import MathPage from "./pages/subjects/MathPage";
import ExamPaperRetriever from "./pages/math/ExamPaperRetriever";
import TopicPaperGenerator from "./pages/math/TopicPaperGenerator";
import Urls from "./utils/urls";
import QuestionUploader from "./pages/math/QuestionUploader";
import ExamPaperBuilder from "./pages/math/ExamPaperBuilder";

declare global {
    interface Window {
        google: any;
    }
}

function App() {
    const {jwt, role} = useAuth(null);
    return (
        <ThemeProvider>
            <div className="app-container">
                {jwt ? <MainNavbar/> : <LoginNavbar/>}
                <div className="content">
                    <Routes>
                        {jwt ? (
                            <>
                                <Route path={Urls.HOME} element={<Home/>}/>
                                <Route path={Urls.MATH} element={<MathPage/>}/>
                                <Route path={Urls.MATH_EXAM_PAPER_RETRIEVAL} element={<ExamPaperRetriever/>}/>
                                <Route path={Urls.MATH_TOPIC_PAPER_GENERATOR} element={<TopicPaperGenerator/>}/>
                                <Route path={Urls.MATH_EXAM_PAPER_BUILDER} element={<ExamPaperBuilder/>}/>
                                {role === 'ADMIN' && <Route path={Urls.MATH_EXAM_QUESTION_UPLOADER} element={<QuestionUploader />} />}
                                <Route path={Urls.USER_AGREEMENT} element={<UserAgreement/>}/>
                                <Route path={Urls.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                <Route path={Urls.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                <Route path={Urls.LOGGING_OUT} element={<LoggingOut/>}/>
                            </>
                        ) : (
                            <>
                                <Route path={Urls.HOME} element={<Home/>}/>
                                <Route path={Urls.GOOGLE_LOADING} element={<GoogleLoading/>}/>
                                <Route path={Urls.LOGIN} element={<Login/>}/>
                                <Route path={Urls.SIGN_UP} element={<SignUp/>}/>
                                <Route path={Urls.VERIFICATION} element={<Verification/>}/>
                                <Route path={Urls.RESET_PASSWORD_REQUEST} element={<ResetPasswordRequest/>}/>
                                <Route path={Urls.RESET_PASSWORD} element={<ResetPassword/>}/>
                                <Route path={Urls.USER_AGREEMENT} element={<UserAgreement/>}/>
                                <Route path={Urls.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                <Route path={Urls.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                <Route path={Urls.LOGGING_OUT} element={<LoggingOut/>}/>
                            </>
                        )}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
                <Footer/>
            </div>
        </ThemeProvider>
    );
}

export default App;
