import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import {getCurrentButtonStyles} from "../utils/Common";

type ThemeToggleButtonProps = {
    toggleTheme: () => void;
    isDarkMode: boolean;
};

const ThemeToggleButton: React.FC<ThemeToggleButtonProps> = ({toggleTheme, isDarkMode}) => {
    return (
        <button onClick={toggleTheme}
                className="absolute top-8 right-4 px-4 py-2 rounded transition duration-300 transform hover:scale-110"
                style={getCurrentButtonStyles(isDarkMode)}>
            <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon}/>
        </button>
    );
};

export default ThemeToggleButton;