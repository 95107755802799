import React from 'react';
import {Link} from 'react-router-dom';
import Urls from "../utils/urls";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-4 w-full">
            <div className="flex flex-wrap justify-center gap-4 text-gray-400">
                <p>&copy; 2024 Revise Wizard. All rights reserved.</p>
                <Link to={Urls.USER_AGREEMENT} className="cursor-pointer hover:underline">User Agreement</Link>
                <Link to={Urls.TERMS_OF_SERVICE} className="cursor-pointer hover:underline">Terms of Service</Link>
                <Link to={Urls.PRIVACY_POLICY} className="cursor-pointer hover:underline">Privacy Policy</Link>
            </div>
        </footer>
    );
};

export default Footer;