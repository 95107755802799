import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useNavigate } from "react-router";
import useAuth from "../utils/useAuth";
import Urls from "../utils/urls";
import { FaHome } from "@react-icons/all-files/fa/FaHome";
import { FaCalculator } from "@react-icons/all-files/fa/FaCalculator";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { useTheme } from "./ThemeContext";

const MainNavbar = () => {
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();
    const { logout, role } = useAuth(navigate);
    const [isMathMenuOpen, setMathMenuOpen] = useState<boolean>(false);

    const handleClick = (path: string): void => {
        navigate(path);
    };

    const toggleSubMenu = (): void => {
        setMathMenuOpen(!isMathMenuOpen);
    };

    const menuStyles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: isDarkMode ? '#86C232' : '#130200'
        },
        bmBurgerBarsHover: {
            background: isDarkMode ? '#130200' : '#86C232'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#1f2937',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    };

    return (
        <Menu styles={menuStyles}>
            <button
                className="menu-item"
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0.5em 1em",
                    color: "var(--item-text-color)",
                    textAlign: "left",
                    textDecoration: "none",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer"
                }}
                onClick={() => handleClick(Urls.HOME)}
            >
                <FaHome style={{ marginRight: "8px" }} /> Home
            </button>
            <div className="menu-item">
                <button
                    className="flex items-center justify-between w-full py-2 px-4"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        color: "var(--item-text-color)",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={toggleSubMenu}
                >
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <FaCalculator style={{ marginRight: "8px" }} /> Math
                    </span>
                    {isMathMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {isMathMenuOpen && (
                    <div className="submenu pl-6 mt-2 space-y-2">
                        <button
                            className="w-full text-left py-2 px-4"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "var(--item-text-color)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => handleClick(Urls.MATH)}
                        >
                            Math Home
                        </button>
                        <button
                            className="w-full text-left py-2 px-4 flex items-center justify-between"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "var(--item-text-color)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => handleClick(Urls.MATH_TOPIC_PAPER_GENERATOR)}
                        >
                            Topic Paper Generator
                            <span className="ml-2 px-2 py-1 text-xs font-bold rounded-full bg-red-500 text-white">Deprecated</span>
                        </button>
                        <button
                            className="w-full text-left py-2 px-4"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "var(--item-text-color)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => handleClick(Urls.MATH_EXAM_PAPER_RETRIEVAL)}
                        >
                            Exam Paper Retrieval
                        </button>
                        <button
                            className="w-full text-left py-2 px-4 flex items-center justify-between"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0.5em 1em",
                                color: "var(--item-text-color)",
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => handleClick(Urls.MATH_EXAM_PAPER_BUILDER)}
                        >
                            Exam Paper Builder
                            <span className="ml-2 px-2 py-1 text-xs font-bold rounded-full bg-green-500 text-white">New</span>
                        </button>
                        {role === 'ADMIN' && (
                            <button
                                className="w-full text-left py-2 px-4"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "0.5em 1em",
                                    color: "var(--item-text-color)",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleClick(Urls.MATH_EXAM_QUESTION_UPLOADER)}
                            >
                                Question Uploader
                            </button>
                        )}
                    </div>
                )}
            </div>
            <button
                className="menu-item"
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    padding: "0.5em 1em",
                    color: "var(--item-text-color)",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "1em"
                }}
                onClick={logout}
            >
                <FaSignOutAlt style={{ marginRight: "8px" }} /> Logout
            </button>
        </Menu>
    );
};

export default MainNavbar;