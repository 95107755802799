import { fallDown as Menu } from 'react-burger-menu';
import '../utils/styles/sidebar.css';
import { useNavigate } from 'react-router';
import Urls from '../utils/urls';
import {FaHome} from "@react-icons/all-files/fa/FaHome";
import React from "react";
import {FaSignInAlt} from "@react-icons/all-files/fa/FaSignInAlt";
import {SiGnuprivacyguard} from "@react-icons/all-files/si/SiGnuprivacyguard";
import {useTheme} from "./ThemeContext";

const LoginNavbar = () => {
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();

    const handleClick = (path: string) => {
        navigate(path);
    };

    const menuStyles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: isDarkMode ? '#86C232' : '#130200'
        },
        bmBurgerBarsHover: {
            background: isDarkMode ? '#130200' : '#86C232'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#1f2937',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    };


    return (
        <Menu styles={menuStyles}>
            <button
                className="menu-item flex items-center w-full py-2 px-4 text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
                onClick={() => handleClick(Urls.HOME)}>
                <FaHome className="mr-3"/> Home
            </button>
            <button
                className="menu-item flex items-center w-full py-2 px-4 text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
                onClick={() => handleClick(Urls.LOGIN)}>
                <FaSignInAlt className="mr-3"/> Login
            </button>
            <button
                className="menu-item flex items-center w-full py-2 px-4 text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
                onClick={() => handleClick(Urls.SIGN_UP)}>
                <SiGnuprivacyguard className="mr-3"/> Sign Up
            </button>
        </Menu>
    );
};

export default LoginNavbar;
