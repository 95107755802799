import React from 'react';
import {useTheme} from "../../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import Urls from "../../utils/urls";
import {getWebsiteUrl} from "../../utils/Url";
import {getCurrentStyles} from "../../utils/Common";
import ThemeToggleButton from "../../components/ThemeToggleButton";

const TermsOfService = () => {
    const {isDarkMode, toggleTheme} = useTheme();

    return <>
        <Helmet>
            <title>Revise Wizard - Terms of Service</title>
            <meta name="description"
                  content="The terms of service for Revise Wizard, which govern your use of the platform."/>
            <link rel="canonical" href={getWebsiteUrl() + Urls.TERMS_OF_SERVICE}/>
        </Helmet>
        <div className="flex flex-col justify-center flex-grow text-white p-8 pb-24"
             style={getCurrentStyles(isDarkMode)}>
            <ThemeToggleButton toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p>These Terms of Service ("Terms") govern your use of the Revise Wizard website located at
                https://www.revisewizard.com/ ("Site"). By using our Site, you agree to these Terms. If you do not agree
                to these Terms, you should not use this Site.</p>
            <h2 className="text-xl font-semibold mt-4">1. User Eligibility</h2>
            <p>There are no specific eligibility requirements for users.</p>
            <h2 className="text-xl font-semibold mt-4">2. Account Creation and Termination</h2>
            <ul className="list-disc list-inside">
                <li>Users must provide a valid email address and a password to create an account.</li>
                <li>Revise Wizard reserves the right to terminate accounts for violations such as theft or impersonation.
                </li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">3. Payment Terms</h2>
            <p>Our payment services are coming soon but are not currently available.</p>
            <h2 className="text-xl font-semibold mt-4">4. Intellectual Property</h2>
            <p>All content on this site, including worksheets and topic booklets, is the property of Revise Wizard. Users
                may use the content for personal use but are not permitted to release or distribute it without
                permission.</p>
            <h2 className="text-xl font-semibold mt-4">5. Limitation of Liability</h2>
            <p>Revise Wizard is not liable for any damages that may occur from the use of our services.</p>
            <h2 className="text-xl font-semibold mt-4">6. Dispute Resolution</h2>
            <p>In the event of a dispute, Revise Wizard will issue a cease and desist letter. If the issue is not resolved,
                we will pursue the matter in court.</p>
            <h2 className="text-xl font-semibold mt-4">7. Changes to These Terms</h2>
            <p>Revise Wizard reserves the right to modify these Terms at any time. Changes will be posted on this page and
                your continued use of the Site after such changes constitutes your acceptance of the new terms.</p>
        </div>
    </>;
};

export default TermsOfService;
